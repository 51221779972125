<template>
  <div class="generic-list orders-table-container">
    <v-list class="mt-4 orders-list">
      <v-list-item
        v-for="order in orders"
        :key="order.orderId"
        :class="{ active: order.orderStatusId === 3 }"
        class="primary--text secondary lighten-2 rounded-sm"
      >
        <v-row no-gutters align="center" class="text-caption text-sm-body-2">
          <!-- data -->
          <v-col cols="6" lg="4">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.date") }}</div>
              <div class="f-value">
                {{
                  $dayjs(
                    order.timeslot.date + " " + order.timeslot.beginTime,
                    "YYYY-MM-DD hh:mm:ss A"
                  ).format("DD/MM/YYYY")
                }}
              </div>
            </div>

            <!-- order number -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.orderNumber") }}</div>
              <div class="f-value">
                {{ order.orderId }}
              </div>
            </div>

            <!-- order type -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.type") }}</div>
              <div class="d-flex align-center default--text font-weight-bold">
                <!--<img
                  aria-hidden
                  :src="serviceImg"
                  height="32"
                  width="37"
                  class="mr-2"
                />-->
                <span class="text-capitalize"
                  >{{ order.shippingAddress.addressName }}
                </span>
              </div>
            </div>
          </v-col>
          <v-divider class="px-0 d-none d-md-flex" vertical></v-divider>

          <!-- totale -->
          <v-col cols="6" sm="6" lg="4" class="pl-10">
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.total") }}</div>
              <div class="f-value">{{ $n(order.grossTotal, "currency") }}</div>
            </div>

            <!-- stato -->
            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.status") }}</div>
              <div class="f-value" v-if="!order.isTest && !order.paymentError">
                <OrderStatusBadge v-bind:order="order" />
              </div>
              <div v-else-if="!order.isTest && order.paymentError">
                <template>
                  <v-tooltip right content-class="order-status-badge-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="d-flex align-center font-weight-bold">
                        Validation Error
                        <v-icon
                          color="primary"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >$info</v-icon
                        >
                      </span>
                    </template>
                    <span>Your order has encountered a problem</span>
                  </v-tooltip>
                </template>
              </div>
              <v-icon v-if="modify && order.paymentError">icon-warning</v-icon>
              <div v-if="order.isTest == true && !order.paymentError">
                <template>
                  <v-tooltip right content-class="order-status-badge-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <span class="d-flex align-center font-weight-bold">
                        Rescheduled
                        <v-icon
                          color="primary"
                          class="ml-2"
                          v-bind="attrs"
                          v-on="on"
                          >$info</v-icon
                        >
                      </span>
                    </template>
                    <span
                      >Il tuo ordine è stato rescheduled per il 03/07/2024</span
                    >
                  </v-tooltip>
                </template>
                <span class="my-2 d-block font-weight-bold"
                  >Prima: 31/05/2024</span
                >
                <span class="my-2 d-block font-weight-bold"
                  >Adesso: 03/07/2024</span
                >
              </div>
            </div>

            <!-- pagamento -->

            <div class="field-wrapper">
              <div class="f-label">{{ $t("orders.header.paymentType") }}</div>
              <div class="f-value">
                {{ $t("paymentType." + order.paymentTypeId) }}
              </div>
            </div>
          </v-col>

          <!-- buttons -->
          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-wrap justify-end mt-2 row-gap"
          >
            <div class="d-flex flex-wrap justify-end col-gap">
              <v-btn
                small
                depressed
                color="primary"
                height="40"
                v-bind:to="
                  modify
                    ? '/profile/modify/' + order.orderId
                    : '/profile/orders/' + order.orderId
                "
              >
                {{ $t("orders.button.viewDetails") }}
              </v-btn>
              <v-tooltip left v-if="!modify">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    v-if="order.isPayable"
                    v-bind:to="'/profile/orders/' + order.orderId"
                  >
                    <v-icon color="white">$creditcard</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.retryPayment") }}</span>
              </v-tooltip>
              <v-tooltip left v-if="!modify">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    @click.stop.prevent="editOrder(order.orderId)"
                    depressed
                    small
                    color="primary"
                    height="40"
                    v-if="order.isEditable"
                  >
                    <v-icon>$edit</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.modifyOrder") }}</span>
              </v-tooltip>
              <v-tooltip left v-if="!modify">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    class="ml-1"
                    @click.stop.prevent="deleteOrder(order.orderId)"
                    v-if="order.isDeletable"
                  >
                    <v-icon>$delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.cancelOrder") }}</span>
              </v-tooltip>

              <v-tooltip left v-if="!modify">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    small
                    depressed
                    color="primary"
                    height="40"
                    v-if="order.orderStatusId == 8 || order.orderStatusId == 10"
                    @click.stop.prevent="addAllToCart(order.orderId)"
                  >
                    <v-icon>$cart</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("orders.buttons.reorderOrder") }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </div>
</template>
<style lang="scss">
.orders-table-container {
  .active {
    background-color: var(--v-primary-lighten2) !important;
  }
  .small {
    font-size: 10px;
    min-height: 16px;
    text-transform: uppercase;
  }
  .field-wrapper {
    margin-bottom: 15px;
    font-size: 14px;
    .f-value {
      font-weight: bold;
      span {
        align-items: center;
      }
    }
  }
  .col-gap {
    column-gap: 15px;
  }
  .row-gap {
    row-gap: 15px;
  }
  .icon-warning:before {
    color: #ff3333;
  }
}
</style>
<script>
import OrderStatusBadge from "@/components/orders/OrderStatusBadge.vue";

import cartInfo from "~/mixins/cartInfo";

import { mapState, mapGetters } from "vuex";

export default {
  name: "OrdersTable",
  props: {
    orders: {
      type: Array
    },
    delivered: {
      type: Boolean
    },
    modify: {
      type: Boolean,
      default: false
    }
  },
  mixins: [cartInfo],
  components: {
    OrderStatusBadge
  },
  data() {
    return {
      /* orderExample: {
        orderId: 1710822,
        orderStatusId: 15,
        orderTypeId: 1,
        orderStatusDescr: "rescheduled",
        addDate: "2024-02-08T09:53:34Z",
        riderUserId: "",
        user: {
          userId: 200330,
          login: ""
        },
        timeslot: {
          timeslotId: 147,
          name: "13:00",
          date: "2024-02-13",
          beginTime: "1:00:00 PM",
          endTime: "3:30:00 PM"
        },
        shippingAddress: {
          addressId: 148326438,
          addressTypeId: 2,
          addressType: "home",
          deliveryServiceId: 2,
          zoneId: 1000034,
          active: 1,
          deliveryAddressId: 148326438,
          shippingAddressId: 148326438,
          addressName: "PATRONATO MILANO",
          address1: "Ripa di Porta Ticinese",
          address2: "",
          addressNumber: "11",
          city: "Milano",
          postalcode: "20143",
          doorbellName: "",
          province: "MI",
          apartmentNumber: "2",
          maxDistance: 0.4,
          latitude: 45.4518849,
          longitude: 9.176488299999999
        },
        deliveryAddress: {
          addressId: 148326438,
          addressTypeId: 2,
          addressType: "home",
          deliveryServiceId: 2,
          zoneId: 1000034,
          active: 1,
          deliveryAddressId: 148326438,
          shippingAddressId: 148326438,
          addressName: "PATRONATO MILANO",
          address1: "Ripa di Porta Ticinese",
          address2: "",
          addressNumber: "11",
          city: "Milano",
          postalcode: "20143",
          doorbellName: "",
          province: "MI",
          apartmentNumber: "2",
          maxDistance: 0.4,
          latitude: 45.4518849,
          longitude: 9.176488299999999
        },
        homeDelivery: true,
        warehouse: {
          warehouseId: 43,
          name: "Savona"
        },
        orderInfos: {},
        totalPrice: 50.3,
        totalPriceVariable: 0,
        netTotal: 50.3,
        deliveryFee: 6.9,
        packageTotal: 0,
        giftTotal: 0,
        refundTotal: 0,
        taxTotal: 0,
        grossTotal: 57.2,
        deliveredNetTotal: 50.3,
        deliveredGrossTotal: 0,
        deliveredGiftTotal: 0,
        deliveredRefundTotal: 0,
        deliveredPackageTotal: 0,
        deliveredDeliveryFee: 6.9,
        paymentTypeId: 8,
        isEditable: false,
        isDeletable: false,
        isParkingEnabled: false,
        isPayable: false,
        isRefundable: false,
        suborders: [
          {
            suborderId: 149138212,
            grossTotal: 50.3,
            suborderInfos: {},
            suborderItems: [
              {
                orderItemId: "171018512",
                quantity: 5,
                weight: 0,
                deliveredQuantity: 0,
                deliveredWeight: 0,
                deliveredGrossTotal: 0,
                grossTotal: 0.8,
                unitPrice: 0.16,
                product: {
                  available: 0,
                  productId: 148000004,
                  codInt: "148000004",
                  codVar: "1",
                  code: "148000004",
                  codeVariant: "1",
                  name: "Offerta LUCE 1",
                  shortDescr: "Descrizione breve dell'offerta Luce 1",
                  description: "",
                  pubState: 1,
                  ivaCategory: {
                    ivaCategoryId: 23,
                    code: "22-N",
                    descr: "",
                    ivaPct: "0.22"
                  },
                  price: 0.16,
                  priceId: 45504679,
                  priceListName: "Home Delivery Assago",
                  priceListId: 4,
                  priceDisplay: 0.16,
                  priceUnitDisplay: "pz",
                  slug: "148000004_offerta-luce-1",
                  categoryId: -1,
                  mediaURL:
                    "https://www.tigros.it/photo2/2020/05/27/0/main/thumb/foto-luce_1.png",
                  mediaURLMedium:
                    "https://www.tigros.it/photo2/2020/05/27/0/main/photo/foto-luce_1.png",
                  dayLock: "",
                  vendor: {
                    vendorId: 0
                  },
                  productClasses: [
                    {
                      productClassId: 10536,
                      productClassGroupId: 10002,
                      name: "OFFERTA DEL MESE",
                      description: "2",
                      iconSource:
                        "/img_layout/product_classes/offerta-mese.svg",
                      productClassTypeId: 2
                    }
                  ],
                  selectOptions: [],
                  productInfos: {
                    MINACQ: "1",
                    WEB_ENABLED: "1",
                    CHECKED: "true",
                    TIPOLOGIA: "Pezzo"
                  },
                  hasDetails: false
                },
                orderItemInfos: {}
              },
              {
                orderItemId: "171018592",
                quantity: 5,
                weight: 0,
                deliveredQuantity: 0,
                deliveredWeight: 0,
                deliveredGrossTotal: 0,
                grossTotal: 49.5,
                unitPrice: 9.9,
                product: {
                  available: 0,
                  productId: 148000002,
                  codInt: "148000002",
                  codVar: "1",
                  code: "148000002",
                  codeVariant: "1",
                  name: "Offerta TELEFONIA 1",
                  shortDescr: "Descrizione breve dell'offerta Telefonia 1",
                  description: "",
                  pubState: 1,
                  ivaCategory: {
                    ivaCategoryId: 23,
                    code: "22-N",
                    descr: "",
                    ivaPct: "0.22"
                  },
                  price: 9.9,
                  priceId: 45504577,
                  priceListName: "Home Delivery Assago",
                  priceListId: 4,
                  priceDisplay: 9.9,
                  priceUnitDisplay: "pz",
                  slug: "148000002_offerta-telefonia-1",
                  categoryId: -1,
                  mediaURL:
                    "https://www.tigros.it/photo2/2020/05/27/0/main/thumb/foto-telefonia_1.png",
                  mediaURLMedium:
                    "https://www.tigros.it/photo2/2020/05/27/0/main/photo/foto-telefonia_1.png",
                  dayLock: "",
                  vendor: {
                    vendorId: 0
                  },
                  productClasses: [
                    {
                      productClassId: 10536,
                      productClassGroupId: 10002,
                      name: "OFFERTA DEL MESE",
                      description: "2",
                      iconSource:
                        "/img_layout/product_classes/offerta-mese.svg",
                      productClassTypeId: 2
                    }
                  ],
                  selectOptions: [],
                  productInfos: {
                    MINACQ: "1",
                    WEB_ENABLED: "1",
                    CHECKED: "true",
                    TIPOLOGIA: "Pezzo"
                  },
                  hasDetails: false
                },
                orderItemInfos: {}
              }
            ]
          }
        ]
      }*/
    };
  },
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    timeslot() {
      return this.getItemByProductId(this.product.productId);
    },
    quantity() {
      return this.item ? this.item.quantity : 0;
    },
    canIRateOrder() {
      return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    }
  },
  methods: {
    rateOrder(orderId) {
      this.$router.push("/order/" + orderId);
    },
    async deleteOrder(orderId) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });
      if (res) {
        this.$emit("delete", orderId);
      }
    },
    editOrder(orderId) {
      this.$emit("editOrder", orderId);
    },
    addAllToCart(orderId) {
      this.$emit("addAllToCart", orderId);
    }
  },

  created() {
    console.log("ORDER", this.order);
  }
};
</script>
